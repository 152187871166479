@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.plus {
  border: 1px solid $color-grey0;
  display: flex;
  align-items: center;
  border-radius: 50%;
  position: relative;
  width: 27px;
  height: 27px;
}

.plus:before,
.plus:after {
  position: absolute;
  left: $spacing-s;
  content: ' ';
  height: 11px;
  width: 1px;
  background-color: $color-grey0;
}
.plus:before {
  transform: rotate(90deg);
}
.plus:after {
  transform: rotate(180deg);
}
