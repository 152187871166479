@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.close {
  border: 1px solid $color-grey0;
  display: flex;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 10px;
  left: 10px;
  z-index: 1;
  cursor: pointer;
}

.close:before,
.close:after {
  position: absolute;
  left: 50%;
  content: ' ';
  height: 50%;
  width: 1px;
  background-color: $color-grey0;

  @include media-tablet {
    left: 15px;
    height: 15px;
  }
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
