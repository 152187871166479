@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.strip {
  min-height: 50px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 30px;

  @include media-mobile-l {
    padding-bottom: $spacing-xxl-4;
  }
}

.strip_blue {
  background-image: linear-gradient(to left, $color-bg1, $color-bg2 50%);

  @include media-mobile-m {
    background: $color-bg3;
  }
}

.strip_grey {
  background-color: $color-grey12;
}

.strip_dark_grey {
  background-image: linear-gradient(to bottom, $color-grey2, $color-grey11);
}

.strip_light_grey {
  background-color: $color-grey5;
}

.strip_green {
  background-color: $color-mtg100;
}

.strip_light_green {
  background-color: $color-mtg20;
}

.strip_white {
  background-color: $color-grey0;
}

.strip_large {
  padding-top: 160px;
  padding-bottom: 130px;

  @include media-tablet {
    padding-top: 110px;
    padding-bottom: 70px;
  }
}

.strip_medium {
  padding-bottom: 130px;

  @include media-tablet {
    padding-bottom: 70px;
  }
}

.strip_gradient {
  background-image: linear-gradient(to bottom, $color-grey0 1%, $color-grey12 100%);
  padding-bottom: 51px;

  @include media-tablet {
    padding-top: $spacing-xxl-4;
    padding-bottom: 70px;
  }

  @include media-mobile-m {
    padding-top: $spacing-xxl-2;
  }
}
