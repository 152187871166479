@mixin description {
  font-size: 14px;
  line-height: 24px;
  color: inherit;

  @media screen and (max-width: 576px) {
    font-size: 13px;
    line-height: 22px;
  }
}

@mixin description_s {
  font-size: 12px;
  line-height: 30px;

  color: inherit;
}

@mixin bodyHeaderTypography {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: inherit;

  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin subHeaderTypography {
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
  color: inherit;

  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 32px;
  }

  @media screen and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;
  }
}

@mixin header {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  color: inherit;

  @media screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }

  @media screen and (max-width: 576px) {
    font-size: 22px;
    line-height: 30px;
  }
}

@mixin button {
  display: block;
  width: fit-content;
  padding: 10px 30px;
  margin: 15px 0;

  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
  font-size: 14px;
  line-height: 1.5;
  color: $color-grey0;
  background-color: $color-orange;
  border-radius: $border-radius;
  border: none;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $color-grey0;
    background-color: $color-orange-hover;
    box-shadow: 0 2px 8px rgb($color-grey100, 0.125);
    text-decoration: none;
    transform: translateY(-1px);
  }

  &:focus {
    outline: 0;
  }
}
