@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.footer {
  padding-top: $spacing-xxl-5;
  padding-bottom: $spacing-xxl-4;

  * {
    font-size: 12px;
    color: $color-grey0;
    a {
      color: $color-grey0;
      white-space: nowrap;
    }
  }

  ul {
    padding-left: 0px;
    li {
      margin: $spacing-xs 0;
      list-style-type: none;
    }
  }

  @include media-tablet {
    padding-top: $spacing-xxl-2;
    padding-bottom: $spacing-xxl-6;
  }

  @include media-mobile-l {
    padding-bottom: $spacing-xxl-4;
  }
}

.footer__columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  p {
    font-size: 13px;
    font-weight: 500;
  }
}

.menu__wrapper {
  display: flex;
  width: 95%;
  justify-content: space-between;

  .footer__menu {
    line-height: 2.67;

    @include media-mobile-l {
      margin-bottom: $spacing-xxl-4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-mobile-l {
    width: 100%;
    flex-direction: column;
  }
}

@mixin details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-xxl-2 0;
  height: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: $spacing-xxl-2 0 $spacing-xxl-4;

  @include media-tablet {
    flex-direction: column;
    align-items: flex-start;
  }

  @include media-mobile-l {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: $spacing-xl 0;
    padding: $spacing-xl 0;
  }
}

.details {
  @include details;
}

.withExtra {
  margin: $spacing-xxl-2 0 0;
}

.extraInfo {
  @include details;

  flex-direction: column;
  border-top: none;
  padding: $spacing-xl 0;
  line-height: 22px;
  align-items: flex-start;
  margin: 0 0 $spacing-xl;

  p {
    // max-width: 620px;
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }
}

.contacts {
  width: 100%;
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;

    li {
      margin: 0;
      display: flex;
      align-items: center;
      flex-grow: 1;

      > img {
        margin-right: 6px;
      }
    }

    @include media-laptop-s {
      li {
        flex-basis: 30%;

        &:nth-child(2),
        &:nth-child(5) {
          justify-content: center;
        }

        &:nth-child(3),
        &:nth-child(6) {
          justify-content: flex-end;
        }
      }
    }

    @include media-mobile-l {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }
}

.company__social {
  display: flex;
  align-items: center;

  a {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    height: 24px;
    width: 24px;
    border-radius: 35px;
    background-color: $color-grey0;
    margin-right: $spacing-m;

    &:nth-child(2) {
      padding-bottom: 2px;
    }

    &:last-child {
      margin-right: 0;
    }

    @include media-tablet {
      margin-right: $spacing-m;
    }
  }

  img {
    width: 14px;
  }
}

.footer__copyright {
  line-height: 22px;
  margin-bottom: $spacing-xxl-4;
  // width: 870px;

  p {
    &:nth-child(1) {
      font-weight: 500;
    }

    &:not(:first-child) {
      margin-bottom: 22px;
    }
  }

  @include media-tablet {
    width: 685px;
    margin-bottom: $spacing-xxl-2;
  }

  @include media-mobile-l {
    width: 100%;
    margin-bottom: $spacing-xxl-4;
  }
}
