@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 70px;
  transition: background-color 0.3s ease, color 0.3s ease;
  background: $color-grey0;

  @include media-tablet {
    height: 64px;
  }

  @include media-mobile-m {
    height: 56px;
  }
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.navigation {
  height: 100%;
  display: flex;
  color: $color-grey0;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include media-tablet {
    flex-direction: column;
    position: absolute;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $color-grey0;
    box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
    display: block;
    height: auto;
    left: 0;
    padding: 15px;
    top: -100vh;
    transition: 0.3s ease-in-out;
    width: 100%;
    z-index: -1;
  }
}

.navigation__active {
  top: 64px;

  @include media-mobile-m {
    top: 56px;
  }
}

.menu {
  height: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  padding: 0;
  margin-left: $spacing-xl;

  @include media-tablet {
    flex-direction: column;
    padding: 0;
    width: 100%;
    margin-left: 0;
  }
}

.menu__item {
  position: relative;
  display: inline-block;
  padding: 0 $spacing-m;
  height: 100%;

  &:hover {
    background-color: $color-mtg60;
    color: $color-mtb100;

    > a,
    span {
      color: $color-mtb100;

      i {
        border-color: $color-mtb100;
        transform: rotate(-135deg);
        margin-bottom: -3px;
      }

      > .locale__img {
        border: 1px solid $color-mtb100;
        border-radius: 50%;
      }
    }

    @include media-tablet {
      background-color: $color-mtg20;
    }
  }

  a,
  span,
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    font-weight: 500;
    font-size: $font-size-s;
    color: $color-mtb100;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    @include media-tablet {
      text-align: center;
      min-height: 50px;
      padding: 15px 0;
      font-size: 18px;
    }

    @include media-mobile-l {
      min-height: 50px;
      padding: 10px 0;
      font-size: 17px;
      white-space: normal;
    }

    @include media-mobile-xs {
      min-height: 30px;
      font-size: 14px;
    }
  }

  @include media-tablet {
    height: fit-content;
    width: 100%;
    padding: 0;

    &:hover {
      background-color: $color-mtg20;
      text-decoration: none;
    }
  }
}

.active__link {
  color: $color-mtb100 !important;
  background-color: $color-mtg100;

  &:hover {
    background-color: $color-mtg100;
  }

  @include media-tablet {
    background-color: $color-mtg100;
  }
}

.menu__item > .item__active,
.locale__wrapper > .item__active {
  text-decoration: underline;
}

.menu__dropdown {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 1;

  padding-top: 5px;
  box-shadow: 0 5px 15px rgba($color-grey100, 0.15);

  @include media-tablet {
    padding: 0 30px $spacing-l 30px;
    position: initial;
    box-shadow: none;
  }
}

.menu__dropdown_active {
  display: block;
}

.subMenu {
  display: block;
  padding: 0;
  border-radius: $border-radius;
  background-color: $color-grey0;
  transition: 0.3s ease-in-out;

  @include media-tablet {
    position: static;
    border-top: 1px solid $color-grey10;
    box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
    top: -100vh;
    transition: 0.3s ease-in-out;
    width: 100%;
    z-index: -1;
    height: fit-content;
    left: 0;
  }
}

.subMenu__item {
  width: 100%;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  padding: 0 $spacing-m;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  a {
    padding: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: $font-size-s;
    color: $color-mtb100;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    padding-left: 0;

    > .locale__img {
      margin-left: 0;
    }

    @include media-tablet {
      padding: 0;
      justify-content: center;
      font-size: 18px;
    }

    @include media-mobile-l {
      min-height: 50px;
      padding: 10px 0;
      font-size: 17px;
      white-space: normal;
    }

    @include media-mobile-xs {
      min-height: 30px;
      font-size: 14px;
    }
  }

  &:hover {
    background-color: $color-mtg40;
    text-decoration: underline;

    > a > .locale__img {
      border: 1px solid $color-mtb100;
      border-radius: 50%;
    }
  }

  @include media-tablet {
    padding: 0;
  }
}

.subMenu__item > a > .locale__img img {
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px $color-grey20;
}

.subMenu > .item__active {
  background-color: $color-mtg100;
  text-decoration: underline;

  &:hover {
    background-color: $color-mtg100;
  }
}

.locale__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-m;
  padding-right: $spacing-xl;
}

.locale__wrapper > a {
  font-family: inherit;
  font-display: swap;
}

.locale__wrapper > span {
  width: fit-content;
  margin-top: 1px;
}

.locale__disabled {
  opacity: 0.45;
  pointer-events: none;
}

.locale__mobile {
  width: 50px;
}

.locale__img {
  display: flex;
  width: 24px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  margin-bottom: 1px;
  position: relative;
  border: 1px solid transparent;
}

.box__shadow {
  background: $color-grey0;
  box-shadow: 0 5px 15px rgba($color-grey100, 0.15);
}

.menu__toggle {
  display: none;
  align-items: center;
  justify-content: center;

  @include media-tablet {
    display: flex;
  }
}

.toggle__button {
  height: 21px;
  width: 24px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  span {
    position: absolute;
    left: 0;

    display: block;
    height: 3px;
    width: 100%;

    border-radius: 3px;
    background-color: $color-mtb100;
    opacity: 1;

    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }
}

.toggle__active > span {
  &:nth-child(2) {
    transform: rotate(45deg);
  }

  &:nth-child(3) {
    transform: rotate(-45deg);
  }

  &:nth-child(1),
  &:nth-child(4) {
    left: 50%;
    top: $spacing-xs;
    width: 0;
  }
}

.toggle__button_dark > span {
  background-color: $color-mtb100;
}

.transparent {
  background: rgba(255, 255, 255, 0);
}

.menu__item_light {
  span {
    color: $color-grey0;
  }

  &:hover {
    background-color: $color-grey0;
    border-radius: 3px;
    span {
      color: $color-mtb100;
      text-decoration: underline;

      i {
        border-color: $color-mtb100;
      }

      > .locale__img {
        border-color: $color-mtb100;
      }
    }
  }
}

.nav__wrapper {
  display: flex;
  height: 100%;
  align-items: center;

  > .controls {
    width: 173px;
    display: flex;
    justify-content: space-between;

    a {
      align-items: center;
      box-shadow: 0 1px 7px 0 $color-grey90;
      display: flex;
      height: 32px;
      justify-content: center;
      margin: 0;
      padding: 1px;
      text-transform: capitalize;
      font-family: inherit;
      font-display: swap;
      font-weight: 500;
      border-radius: 3px;

      &:first-child {
        width: 64px;
        border: solid 1px $color-grey0;
        box-shadow: none;
      }

      &:last-child {
        width: 97px;
      }

      @include media-tablet {
        height: 37px;
        width: 130px;
        font-size: 18px;
      }

      @include media-mobile-l {
        font-size: 17px;
      }

      @include media-mobile-xs {
        height: 32px;
        width: 115px;
        font-size: 14px;
        min-height: 30px;
      }
    }

    @include media-tablet {
      margin-top: $spacing-s;
      margin-bottom: $spacing-s;
      width: fit-content;
      height: fit-content;
    }
  }

  .controls_white {
    a {
      &:first-child {
        color: $color-mtb100;
        border-color: $color-mtb40;

        @include media-tablet {
          margin-right: $spacing-s;
        }
      }

      &:last-child {
        box-shadow: 0 1px 3px 0 $color-neutrals-grey-10;
      }

      @include media-tablet {
        width: fit-content !important;
        padding: 0 18px;
      }
    }
  }

  @include media-tablet {
    flex-direction: column;
    height: fit-content;
  }
}

.menu__lang {
  margin-right: 12px;
  height: 100%;

  > .menu__item {
    padding: 0;

    @include media-tablet {
      background-color: $color-grey0;
      display: flex;
      height: fit-content;
      justify-content: center;
      margin: 0;
      padding: 0 $spacing-m;
    }
  }

  @include media-laptop {
    li:first-child {
      width: 80px;
    }
  }

  @include media-tablet {
    padding: 0;
    margin: 0;
  }
}
