@import '../../../styles/_variables.scss';

.wrapper {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow {
  border: solid $color-grey0;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 2.5px;
  margin-bottom: 5px;
  margin-left: 7px;
  transform: rotate(45deg);
}

.wrapper > .active {
  border-color: $color-mtb100;
  transform: rotate(-135deg);
  margin-bottom: -3px;
}

.wrapper > .dark {
  border-color: $color-mtb100;
}
