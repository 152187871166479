@import '../styles/_variables.scss';
@import '../styles/reset.scss';
@import '../styles/mixins.scss';
@import '../styles/media.scss';
@import '../styles/swiper.scss';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $color-mtb100;
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: normal;
}

button {
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  &.is-highlighted {
    width: fit-content;
    padding: 3px 8px;
    background-color: $color-mtg100;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: normal;
  color: $color-mtb100;
}

h1,
.h1 {
  font-size: 32px;
}

h2,
.h2 {
  font-size: 24px;
}

h3,
.h3 {
  font-size: 18px;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
  &:active {
    color: inherit;
  }
}

span {
  color: inherit;
}

ul {
  font-size: 14px;
  padding-left: 14px;

  li {
    list-style-type: disc;
  }
}

@media screen and (max-width: 768px) {
  h1,
  .h1 {
    font-size: 26px;
  }

  h2,
  .h2 {
    font-size: 20px;
  }

  h3,
  .h3 {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  h1,
  .h1 {
    font-size: 22px;
  }

  h2,
  .h2 {
    font-size: 16px;
  }

  body,
  h3,
  .h3 ul {
    font-size: 12px;
  }
}

hr {
  border: 0px;
  width: 100%;
  opacity: 0.8;
  border-bottom: solid 1px $color-grey15;
}

strong {
  font-weight: 600;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder,
/* Internet Explorer 10-11 */
:-ms-input-placeholder,
/* Microsoft Edge */
::-ms-input-placeholder {
  color: $color-mtb40;
  opacity: 1; /* Firefox */
}

// Global classes
.container {
  max-width: 1150px;
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
  margin-left: auto;
  margin-right: auto;

  &--fluid {
    max-width: initial;
  }

  &--fullWidth {
    max-width: initial;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  @media screen and (max-width: 1200px) {
    padding-right: $spacing-xxl-4;
    padding-left: $spacing-xxl-4;
  }

  @media screen and (max-width: 992px) {
    padding: 0 $spacing-xl;
  }

  @media screen and (max-width: 576px) {
    padding-right: $spacing-l;
    padding-left: $spacing-l;
  }
}

@mixin markdown-box {
  max-width: 900px;
  margin: 0 auto;
  @include description;

  &--thin {
    max-width: 700px;
  }

  h1 {
    @include header;
  }

  h3 {
    @include bodyHeaderTypography;
  }

  a {
    color: $color-orange;
  }

  ul {
    margin-left: 15px;
  }

  > * {
    margin-bottom: 15px;
  }

  > :last-child {
    margin-bottom: 0;
  }

  img,
  video {
    max-width: 100%;
  }

  div {
    > * {
      margin-bottom: 15px;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  p iframe {
    width: 100%;
  }
}

.markdown-box {
  @include markdown-box;
}

.markdown-box--wide {
  @include markdown-box;

  max-width: 100%;
}

.markdown-box-dark {
  max-width: 900px;
  margin: 0 auto;
  @include description;

  &--thin {
    max-width: 700px;
  }

  h1 {
    @include header;
  }

  h3 {
    @include bodyHeaderTypography;
  }

  a {
    color: $color-mtb100;
    text-decoration: underline;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }

  ul {
    margin-left: 15px;
  }

  > * {
    margin-bottom: 15px;
  }

  > :last-child {
    margin-bottom: 0;
  }

  img,
  video {
    max-width: 100%;
  }

  div {
    > * {
      margin-bottom: 15px;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  p iframe {
    width: 100%;
  }
}

.not-found-box {
  margin: 60px 0;

  &__visual,
  .link {
    margin: 0 auto;
  }

  &__visual {
    display: block !important;
    width: 80%;
    max-width: 450px;
  }

  p {
    text-align: center;
    margin: $spacing-l 0;
  }
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  padding: 0;

  &:first-of-type {
    margin-bottom: 60px;
  }

  &:last-of-type {
    margin-top: 60px;
  }

  li {
    list-style-type: none;
    user-select: none;
    cursor: pointer;

    a {
      width: 100%;
      padding: 7.5px 15px;
      border: 1px solid $color-grey10;
      outline: none;
      color: $color-mtb100;

      &:hover {
        text-decoration: none;
      }

      &:not(:last-of-type) {
        border-right: 0;
      }

      &.is-active {
        background-color: $color-mtg100;

        &:hover {
          background-color: $color-mtg100;
        }
      }
    }

    &:first-of-type {
      a {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    &:last-of-type {
      a {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .pagination-buttons {
    li {
      &:hover {
        a {
          background-color: rgba($color-grey10, 0.5);
        }
      }
    }
  }
}

.gatsby-image-wrapper img {
  transition: none !important;
}

.section__divider {
  display: none;
}

@media screen and (max-width: 576px) {
  .section__divider {
    display: block;
    height: 1px;
    width: 40px;
    margin: 0 auto;
    background-color: $color-mtb20;
  }
}

.socialShare {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

/* ===== CUSTOM HUBSPOT FORM STYLING ===== */

.customHubspotForm .form-columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  padding-bottom: 14px;
  @include media-mobile-l {
    grid-template-columns: 1fr;
  }
}

.customHubspotForm .hs_recaptcha {
  display: none;
}

.customHubspotForm input,
.customHubspotForm textarea,
.customHubspotForm select {
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
  padding: 14px;
  border: 1px solid $color-mtb40;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  color: $color-mtb100;
}

/* Placeholders look like form labels */
.customHubspotForm input::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
  color: $color-mtb40;
  font-weight: 400;
}

.customHubspotForm input:not(:placeholder-shown) {
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
  border-color: $color-mtb100;
}

.customHubspotForm li,
.customHubspotForm ul {
  padding-left: 0px;
  list-style: none;
}

/* Submit button styling */
.customHubspotForm .hs-button.primary {
  display: block;
  width: 140px;
  margin: 15px auto;
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  background-color: $color-mtg100;
  border-radius: 3px;
  border: solid 1px $color-mtg115;
  text-align: center;
  color: $color-mtb100;
  padding: 8px 4px;
}

.customHubspotForm .hs-button.primary:hover {
  box-shadow: 0 2px 8px rgb($color-grey100, 0.125);
  text-decoration: none;
  transform: translateY(-1px);
}

/* Responsive stacking on mobile */
@media (max-width: 768px) {
  .customHubspotForm form {
    grid-template-columns: 1fr;
  }

  .customHubspotForm .hs-button.primary {
    grid-column: span 1;
  }
}

.customHubspotForm .hs-error-msg {
  color: red;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  font-display: swap;
}
