@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.pill {
  border-radius: 10px;
  border: solid 1px $color-neutrals-grey-20;
  background-color: $color-neutrals-grey-10;
  padding: 1px 6px;
  font-size: 9px;
  line-height: normal;
  font-weight: 500;
  height: 16px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
}

.pill_withKill {
  padding-right: 3px;
}
