@import '../../../styles/_variables.scss';
@import '../../../styles/media.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron {
  display: flex;
  align-items: center;

  svg {
    stroke-width: 1px;
    color: $color-mtb100;
    border: 1px solid $color-mtb100;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

.divider {
  width: 100%;
  border-bottom: 1px solid $color-mtb100;
}
