@import '../../../styles/_variables.scss';
@import '../../../styles/mixins.scss';

.link {
  color: $color-orange;
  font-family: inherit;
  font-display: swap;
  cursor: pointer;
}

.link_dark {
  color: $color-mtb100;
  font-weight: 500;
}

.link_cta {
  @include button;
  color: $color-grey0;

  &:hover {
    text-decoration: none;
  }
}

.link_bordered {
  @include button;
  background-color: $color-grey0;
  border-radius: 3px;
  border: solid 1px $color-mtb100;
  text-align: center;
  color: $color-mtb100;

  &:hover {
    text-decoration: none;
    background-color: $color-grey0;
  }
}

.link_ctaMint {
  @include button;
  background-color: $color-mtg100;
  border-radius: 3px;
  border: solid 1px $color-mtg115;
  text-align: center;
  color: $color-mtb100;

  &:hover {
    text-decoration: none;
    background-color: $color-mtg100;
    color: $color-mtb100;
  }
}

@mixin link_mint {
  @include button;
  background-color: $color-mtg100;
  border-radius: 3px;
  border: solid 1px $color-mtg115;
  text-align: center;
  color: $color-mtb100;
  padding: $spacing-xs $spacing-xxs;

  &:hover {
    text-decoration: none;
    background-color: $color-mtg100;
    color: $color-mtb100;
  }
}

.link_mint {
  @include link_mint;
}

.link_mintShadow {
  @include link_mint;
  box-shadow: 0 1px 5px 0 rgba(38, 38, 38, 0.5);
}

.link_underlined {
  @include description;
  text-align: center;
  color: $color-grey0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.link_ghost {
  @include button;

  color: $color-grey0;
  background-color: transparent;
  border: 1px solid $color-grey0;

  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
}

.link_disabled {
  opacity: 0.45;
  pointer-events: none;

  &:hover {
    text-decoration: none;
  }
}

.link_centered {
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
