@import '../styles/_variables.scss';

.ant-btn,
.ant-btn:hover,
.ant-btn:focus {
  color: $color-mtb100 !important;
  border-color: $color-mtb100 !important;
  cursor: pointer !important;
  border-radius: 0px !important;
  font-family: 'Fira Sans', sans-serif !important;
  font-display: swap;
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: $color-grey0 !important;
  background: $color-mtb100 !important;
  border-color: $color-mtb100 !important;
}
